import React from 'react'
import ProductPreview from '../product/Preview'
import { useMaterialsData } from '../../lib/materials_hook'
import { ProductMaterial , ProductData } from '../../types'
import { isConnectedMaterialBundle } from '../../utils/Mapping'
import LinearProgress from '@mui/material/LinearProgress'
import { useAlternativesData } from '../../lib/products/alternatives_hook'
import { trackEvent } from '../../utils/ArtboxoneTracking'

const AlternativesOverview = (options: any) => {
  const materialsData = useMaterialsData()

  const alternativesData = useAlternativesData({
    motiveId: options.motiveId,
    material: options.material,
  })

  const onClick = (e: any, a: any) => {
    trackEvent({
      category: 'alternatives',
      action: 'click',
      label: options.label + '-' + a,
    })
  }

  if (typeof alternativesData === 'undefined' || materialsData.length == 0) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <LinearProgress />
            </div>
          </div>
        </div>
      </>
    )
  } else {
    const productsData: ProductData[] = Object.values(alternativesData)

    if (productsData.length == 0) {
      return <></>
    } else {
      return (
        <>
          <div className="row">
            {productsData.map((productData: ProductData, i) => (
              <div className="col-6 col-sm-4" key={'product_preview_' + i}>
                <ProductPreview
                  material={options.material}
                  materials={materialsData.filter(
                    (materialData: ProductMaterial) => {
                      return isConnectedMaterialBundle(
                        materialData,
                        options.material,
                      )
                    },
                  )}
                  productData={productData}
                  setOnClick={(event: any) => onClick(event, i)}
                />
              </div>
            ))}
          </div>
        </>
      )
    }
  }
}

export default AlternativesOverview
